import {useState} from 'react'
import { Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export default function Login() {
  const [ids, setids] = useState({username:"",password:""});
  const [loading, setloading] = useState(false)

  const movePage = useNavigate();

  const login=()=>ids.username=="admin" && ids.password=="admin" ?  changeView() : alert("identifiants invalides")
  const changeView=()=>{setloading(true); setTimeout(()=>{movePage("/home");},2000)}
  
  return (
   <div className="min-h-screen items-center justify-center flex">
        <div className="p-5  border rounded-xl border-gray-500">
            <div className="text-left">
              <img src={require("./img/logo.svg").default} style={{width:100,filter:"invert(1)"}} className="mx-auto mb-2"/>
            </div>
            <h1 className='text-center text-xl bold'>SE CONNECTER</h1>
            username <br/>
            <input className='w-full p-1 rounded bg-slate-600' onChange={(e)=>setids({...ids,username:e.target.value})}/>
            password <br/>
            <input className='w-full p-1 rounded bg-slate-600' type="password" onChange={(e)=>setids({...ids,password:e.target.value})}/>
              <a className='block p-1 w-full rounded bg-orange-600 text-center mt-4' onClick={login}>
                  {loading ? <span class="inline-block w-5 h-5  border-l border-l-[#e0e0e0]  border-t border-t-[#d7d7d7] animate-spin rounded-full" viewBox="0 0 24 24"></span> : "CONNEXION" }
              </a>
        </div>
   </div>
  )
}
