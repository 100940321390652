import React from 'react'
import Accordion from './Accordion'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


export  function Home() {
  return (
    <>
    <div className="p-4">
      <div className="text-left mb-3">
          <img src={require("../img/logo.svg").default} style={{filter:"invert(1)"}} className="mx-auto mb-2"/>
      </div>
      

        <Accordion  title="Offre éditoriale">
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
                  Collections générales
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
                Collections thématiques
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Personnalisation des contenus
            </div>
        </Accordion>
        <Accordion  title="Offre éducation">
        <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
                Partenaires de l'Éducation nationale 
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
                Projet éducatif Édito
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Les profs en parlent
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Action avec les médiathèques
            </div>
        </Accordion>
        <Accordion  title="Accueil & culture">
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Hôpitaux
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
                Salles d'attente
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
                Transports  
            </div>
        </Accordion>
        <Accordion  title="Short édition">
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Qui sommes-nous ?
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Les auteurs et les autrices
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Entreprise à mission  
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              L'équipe 
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Contact 
            </div>
            <div className="flex items-center gap-2 text-xl mb-1">
              <ArrowRightIcon className='text-orange-600'/>
              Presse 
            </div>
        </Accordion>

        <Accordion  title="Histoires">
          <div className="bg-white text-black rounded-xl flex justify-between items-center py-1 mb-2">
              <div className="flex items-center gap-2 text-xl mb-1">
                <ArrowRightIcon className='text-orange-600'/>
                    BD
              </div>
              <div>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 1 minute</span>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 3 minute</span>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 5 minute</span>
              </div>

          </div>
          <div className="bg-white text-black rounded-xl flex justify-between items-center py-1 mb-2">
              <div className="flex items-center gap-2 text-xl mb-1">
                <ArrowRightIcon className='text-orange-600'/>
                POEM
              </div>
              <div>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 1 minute</span>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 3 minute</span>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 5 minute</span>
              </div>

          </div>
          <div className="bg-white text-black rounded-xl flex justify-between items-center py-1 mb-2">
              <div className="flex items-center gap-2 text-xl mb-1">
                <ArrowRightIcon className='text-orange-600'/>
                ROMAN
              </div>
              <div>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 1 minute</span>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 3 minute</span>
                  <span className='inline-block bg-orange-600 text-white text-sm px-2 py-1 rounded-xl mx-1'> 5 minute</span>
              </div>

          </div>
        </Accordion>

        
    </div>
    </>
  )
}
