import "./App.css"
import Login from "./Login"
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import { Home } from "./components";




export default function App() {
  return (
    <div className="min-h-screen w-full bg-slate-700 text-gray-100">
      <HashRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<Home />} />
            </Routes>
      </HashRouter>
    </div>
  )
}
