import React, { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function Accordion({title,children}) {
    const [_IsOpen, set_IsOpen] = useState(false)


  return (
    <div className='border border-gray-500 p-2 transition-all rounded-md mb-2'>
        <div className='flex justify-between text-3xl items-center py-3' onClick={()=>set_IsOpen(!_IsOpen)}>
            <div className={`bold uppercase ${_IsOpen && "text-orange-600"}`}>
                {title}
            </div>
            <div className={`transition-all ${_IsOpen && "rotate-90 text-orange-600"}`}>
                <ArrowForwardIosIcon /> 
            </div>
        </div>
        <div className='transition-all'>
        {_IsOpen &&
            <div>
                {children}
            </div>
        }
        </div>
        
    </div>
  )
}
